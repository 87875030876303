$greenl: #19bea0;
$greend: #19a082;
$bluel: #379bdc;
$blued: #2882b9;
$whale: #374b5f;
$midnight: #2d4150;
$clouds: #ebf0f0;
$silver: #bec3c8;
$iron: #96a5a5;
$gray: #7d8c8c;
$sun: #f0c30f;
$orange: #f09b0f;
$carrot: #e68223;
$pumpkin: #d25000;
$redl: #e6503c;
$redd: #be3228;
$amethyst: #9b5ab4;
$purple: #8c41aa;
$cinnabar: #e44d2e;
