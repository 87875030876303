@import "colors";
@import "responsive";
header {
  min-height: 56px;
  @media #{$standard} {min-height: 70px}
}
main {
  padding: 8px;
  font-size: 12pt; color: $midnight;
  & * {font-size: 12pt}
  h1 {
    font: {size: 14pt; weight: 700;}
    text: {align: right; transform: uppercase;}
    color: white;
    background-image: radial-gradient(at 10px 10px, $redl, $redd);
    box-shadow: 2px 2px 6px $iron;
    border-radius: 128px 2px 2px 128px;
    padding: 4px 8px;
    @media #{$mobile} {
      border-bottom: 2px $redl solid;
      color: $redl;
      background-image: none;
      border-radius: 0;
      box-shadow: none;
      margin: 0 2px 2px 2px; padding: 2px;
      font-size: 12pt; weight: 700;
    }
  }
}
